import styled from '@emotion/styled';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { IMenu } from 'src/interface/layout';
import { Media } from 'src/interface/media';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import useResponsive from 'src/utils/devices';

import { SHOW_COMMEMORATE } from 'src/constants';
import SectionCover from 'src/section-events/events-009/section-cover';
import { TInitPropsMember } from 'src/services/member-auth/member.auth.service';
import { controller } from 'src/switch-themes';
import { splitRote } from 'src/utils/helper';

const WidgetLogin = dynamic(import('src/services/member-auth/components/widget-login'));
const WidgetFollow = dynamic(import('src/components/widget/widget-follow'));
const WidgetSearchFullPage = dynamic(import('src/components/widget/widget-search-full-page'));
const NavDesktop = dynamic(import('src/components/layout/nav-desktop'));
const NavMobile = dynamic(import('src/components/layout/nav-mobile'));

type PCategory = TInitPropsMember & {
  data: IMenu[];
  sectionCover?: any;
  eventDataLayer: any;
  gray?: boolean;
};
interface Props {
  gray: boolean;
}

const { title, name, image } = controller;
const Header: NextPage<PCategory> = ({ data, sessionSevId, sectionCover, eventDataLayer }) => {
  const _data: IMenu[] = data;
  const router = useRouter();
  const responsive: Media = useResponsive();
  return (
    <>
      <HeaderLogo className='container' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
        {router.asPath === `${splitRote(router) || '/'}` ? (
          <h1 className='text-header-logo'>
            <a
              href={`${splitRote(router)}/`}
              title={title}
              aria-label={title}
              // ! DATALAYER
              onClick={() =>
                sendDataLayerGlobal({
                  type: DATALAYER_TYPE.TRACK_POSITION,
                  router: router?.pathname,
                  section: 'header',
                  position: 'on:header',
                  data: {
                    title: 'logo',
                    heading: 'logo'
                  }
                })
              }
            >
              <span>{title}</span>
              <img className='logo-img' src={`${image.logoHeader}`} title={name.en} alt={name.en} width={450} height='100%' loading='lazy' />
            </a>
          </h1>
        ) : (
          <a
            href={`${splitRote(router)}/`}
            title={title}
            aria-label={title}
            // ! DATALAYER
            onClick={() =>
              sendDataLayerGlobal({
                type: DATALAYER_TYPE.TRACK_POSITION,
                router: router?.pathname,
                section: 'header',
                position: 'on:header',
                data: {
                  title: 'logo',
                  heading: 'logo'
                }
              })
            }
          >
            <img className='logo-img' src={`${image.logoHeader}`} title={name.en} alt={name.en} width={450} height='100%' loading='lazy' />
          </a>
        )}
      </HeaderLogo>
      <SectionCover sectionCover={sectionCover} />
      {/* <BgTagWrapper style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}> */}
      <BgTagWrapper gray={SHOW_COMMEMORATE === 'true'}>
        <div className='container flex' >
          <div className='flex' >
            <WidgetLogin sessionSevId={sessionSevId} />
            <WidgetSearchFullPage />
          </div>
          <div className='flex' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
            <li className={`listItem --live`}>
              <a
                href={`${splitRote(router)}/category/nationtv/live`}
                aria-label='nav-desktop'
                title='live'
                // ! DATALAYER
                onClick={() =>
                  sendDataLayerGlobal({
                    ...eventDataLayer,
                    data: {
                      title: 'live',
                      heading: 'live'
                    }
                  })
                }
              >
                <span>
                  <div className='live-button'>
                    <svg x='0px' y='0px' width='17px' height='15.8px' viewBox='0 0 17 15.8'>
                      <path d='M8.5,6.8c-0.9,0-1.7,0.8-1.7,1.7c0,0.9,0.8,1.7,1.7,1.7c0.9,0,1.7-0.8,1.7-1.7C10.2,7.6,9.5,6.8,8.5,6.8z'></path>
                      <path d='M13.6,8.5c0-2.8-2.2-5.1-5-5.2c-2.8,0-5.1,2.2-5.2,5c0,0,0,0.1,0,0.1c0,1.8,1,3.5,2.6,4.4l0.9-1.4c-1.6-1-2.1-3-1.2-4.6s3-2.1,4.6-1.2c1,0.6,1.7,1.7,1.7,2.9c0,1.2-0.6,2.3-1.7,2.9l0.9,1.4C12.7,12,13.6,10.3,13.6,8.5L13.6,8.5z'></path>
                      <path d='M8.5,0C3.8,0,0,3.8,0,8.5c0,3,1.6,5.8,4.3,7.3l0.9-1.4c-3.3-1.9-4.4-6.1-2.5-9.3s6.1-4.4,9.3-2.5c2.1,1.2,3.4,3.5,3.4,5.9c0,2.4-1.3,4.7-3.4,5.9l0.9,1.4c4.1-2.3,5.5-7.5,3.1-11.6C14.4,1.6,11.6,0,8.5,0z'></path>
                    </svg>
                    <p>live</p>
                  </div>
                </span>
              </a>
            </li>
            <div style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
              <WidgetFollow
                showTitle={false}
                right={true}
                // ! DATALAYER
                eventDataLayer={{
                  type: DATALAYER_TYPE.TRACK_POSITION,
                  router: router?.pathname,
                  section: 'header',
                  position: 'on:header'
                }}
              />
            </div>
          </div>
        </div>
      </BgTagWrapper>
      <HeaderMenu className={SHOW_COMMEMORATE === 'true' ? 'desktop desktop-gray' : 'desktop'}>
        {responsive.isTabletPro && (
          <div className={`container header`}  >
            <NavDesktop
              sessionSevId={sessionSevId}
              _data={_data}
              // ! DATALAYER
              eventDataLayer={{
                type: DATALAYER_TYPE.TRACK_POSITION,
                router: router?.pathname,
                position: 'on:header',
                section: 'header'
              }}
            />
            {/* <AiOutlineSearch /> */}
          </div>
        )}
      </HeaderMenu>
      <HeaderMenu className={SHOW_COMMEMORATE === 'true' ? 'mobile desktop-gray' : 'mobile'}>
        {/* {responsive.isTabletMini && ( */}
        <>
          <div className={`container header`}  >
            <NavMobile
              _data={_data}
              // ! DATALAYER
              eventDataLayer={{
                type: DATALAYER_TYPE.TRACK_POSITION,
                router: router?.pathname,
                position: 'on:header',
                section: 'header'
              }}
            />
          </div>
          <WidgetLogin sessionSevId={sessionSevId} />
          <WidgetSearchFullPage />
        </>
        {/* // )} */}
      </HeaderMenu>
    </>
  );
};

export default Header;

const BgTagWrapper = styled.div<Props>`

  border-bottom: 2px solid #fff;
  min-height: 56px;
  padding: 10px 0;
  /* background: linear-gradient(90deg, #1e3256 0%, rgba(30, 50, 86, 0) 37.78%),
    radial-gradient(72.41% 680.86% at 73.96% -200%, rgba(25, 69, 136, 0) 55.74%, #4377ca 100%) ,
    radial-gradient(47.99% 637.55% at 17.71% -79.2%, #4377ca 0%, #1e3257 99.99%, #1e3256 100%); */
    background: ${props => ((props?.gray) ? 'linear-gradient(90deg, #545454 0%, rgba(30, 50, 86, 0) 37.78%),radial-gradient(72.41% 680.86% at 73.96% -200%, rgba(25, 69, 136, 0) 55.74%, #303030 100%) ,radial-gradient(47.99% 637.55% at 17.71% -79.2%, #303030 0%, #303030 99.99%, #303030 100%)' : '#27398B')};

   
    .flex{

      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    li.listItem {
      margin-right: 20px;
      display: flex;
      align-items: center;
      padding: 0px 15px;
      height: 54px;
      .nav-sub-menu {
        position: absolute;
        top: 54px;
        overflow: visible;
        a {
          font-weight: 500;
          font-size: 16px;
          color: var(--primary-color);
          &:hover {
          }
        }
      }
      &.--live{
        height: 100%;
        padding: 2px 15px;
        background: #eb212d;
      }
      span {
        color: var(--text-color);
        font-weight: 500;
        font-size: 16px;
        vertical-align: middle;
        text-transform: capitalize;
        @media (max-width: 1024px) {
          font-size: 14px;
        }
        @media (max-width: 900px) {
          font-size: 12px;
        }
      }
      svg {
        vertical-align: middle;
        color: var(--text-color);
        margin-left: 5px;
      }

      &:hover {
        z-index: 7;
      }
      &.--active {
        span,
        svg {
          color: #ffffff;
        }
      }
    }
    .live-button {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    svg {
      margin-right: 10px;
    }
    svg path {
      fill: #fff;
      /* Copy this @keyframes block to your CSS*/
      @keyframes signalanimate {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      &:nth-of-type(1) {
        -webkit-animation: signalanimate 2s infinite;
        animation: signalanimate 2s infinite;
      }
      &:nth-of-type(2) {
        -webkit-animation: signalanimate 2s infinite;
        animation: signalanimate 2s infinite;
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
      }
      &:nth-of-type(3) {
        -webkit-animation: signalanimate 2s infinite;
        animation: signalanimate 2s infinite;
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
      }
    }
  }
    @media (max-width: 768px) {
    display: none;
  }
`;
const HeaderLogo = styled.header`
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  max-width: 100%;
  padding: 0 15px;
  .text-header-logo {
    margin: 0;
    overflow: hidden;
    position: relative;

    span {
      text-indent: 1000em;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      position: absolute;
      width: 100%;
    }
  }
  @media (max-width: 690px) {
    min-height: 105px;
    .logo-img {
      width: 250px;

      padding: 15px 0;
    }
  }
  .logo-img {
    padding: 30px 0;
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: auto;
    width: 25%;
    @media (max-width: 690px) {
      width: 250px;

      padding: 20px 0;
    }
  }
`;

const HeaderMenu = styled.div`
  top: 0;
  position: sticky;
  min-height: 54px;
  /* background: var(--secondary-gradient); */
  background: #27398B;
  z-index: 25;


  &.desktop {
    display: block;
    .header {
      padding: 0 15px 0 0px;
    }
    @media (max-width: 768px) {
      display: none;
    }
    
  }
  &.desktop-gray{
    background: linear-gradient(90deg, #303030 0%, rgba(30, 50, 86, 0) 37.78%),
    radial-gradient(72.41% 680.86% at 73.96% -200%, rgba(25, 69, 136, 0) 55.74%, #303030 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(47.99% 637.55% at 17.71% -79.2%, #303030 0%, #303030 99.99%, #303030 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  }
  &.mobile {
    align-items: center;
    min-height: 48px;
    display: none;
    @media (max-width: 768px) {
      display: flex;
      .header {
        padding: 0 0 0 10px;
      }
    }
  }
  .header {
    svg {
      font-size: 26px;
      color: var(--text-color);
    }
  }
  .header-ul {
    display: flex;
    gap: 20px;
    .header-li {
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: #fff;
        p {
          a {
            color: #000;
          }
        }
      }
      p {
        padding: 15px 5px;
        width: 100%;
        height: 100%;
        a {
          transition: all 0.3s ease-in-out;
          width: 100%;
          height: 100%;
          color: var(--text-color);
        }
      }
    }
  }
`;
